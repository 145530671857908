import React from "react";
import { ThemeProvider } from "@reinventa-formacion/ui-core";
import { Helmet } from "react-helmet";
import "normalize.css";

import "./src/config/site.css";
import theme from "./src/config/theme";

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet
        link={[
          {
            rel: "preconnect",
            href: "https://fonts.gstatic.com",
            crossorigin: "crossorigin",
          },
        ]}
      />
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </>
  );
};
